.x-out {
    fill: #0D99FF;
    cursor: pointer;

    width: 32px;
    height: 32px;
    left: 612px;
    top: 24px;

    border-radius: 0px;

    /* Vector */
    position: absolute;
    left: 90%;
}

.popup--wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup--container {
    width: 668px;
    height: 453px;
    z-index: 10;
    /* left: 360px; need to make these dynamic */
    /* top: 100px; */

    box-sizing: border-box;

    position: absolute;
    /* left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%; */
    /* margin-bottom: 5%; */

    background: radial-gradient(73.02% 59.14% at -21.03% 107.5%, rgba(16, 198, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(84.92% 78.57% at 92.86% 21.43%, rgba(16, 198, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #FFFFFF;
    border: 2px solid #0D99FF;
    box-shadow: 4px 4px 4px rgba(151, 182, 204, 0.25);
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup--title {
    position: relative;
    top: 24px;

    height: 48px;
    margin-bottom: 0%;

    font-family: 'Fredoka';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;

    color: #000000;
}

.caption {
    /* List your student’s names here and we’ll automatically add them to the existing seats. Number of seats: 18 */
    position: absolute;
    top: 20%;
    /* bottom: 66.89%; */

    max-width: 65%;

    font-family: 'Fredoka';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #0F3D5F;

}

.mismatch-alert {
    /* Not enough seats, please remove XX names. */

    position: absolute;
    visibility: hidden;
    top: 31%;
    bottom: 62.44%;

    font-family: 'Source Sans 3';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #ED0505;
}

.textbox {
    /* Rectangle 4 */
    width: 404px;
    height: 180px;
    
    border-radius: 5px;
    
    box-sizing: border-box;

    position: absolute;
    top: 35%;

    background: #FFFFFF;
    border: 1px solid #0A7ACC;
    border-radius: 5px;
    /* transform: rotate(-90deg); */

    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    padding: 10px;
    resize: none;
}

.defaultText{
    top: 0px;
    color: #B7B7B7;
    margin-top: 0px;
}

.button {
    position: relative;
    height: 37px;
    width: 238px;
    bottom: -70%;
}

.hidden--popup {
    visibility: hidden;
    height: 0%;
}

.shading {
    position: absolute;
    left: 0%;
    top: 0%;

    width: 100vw;
    height: 100vh;

    z-index: 9;

    background: rgba(255, 255, 255, 0.7);
}

.visible {
    visibility: visible;
}

.size--warning {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

}