.login--container{
    padding: 24px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10%;
    gap: 12px;
}

.back-button{
    position: absolute;
    left: 20px;
    top: 20px;
}

@media (max-width: 600px) {
    #left-back-button{
        visibility: hidden;
    }
    #login-back-button{
        visibility: visible;
    }
}