.sandbox {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 12px;
  z-index: -2;
}


