.menu {
    position: absolute;
    width: 350px;
    height: 60px;
    left: 0px;
    top: 26px;

    z-index: 2;

    background: #FFFFFF;
    border: 1px solid #0D99FF;
    box-shadow: 4px 4px 4px rgba(151, 182, 204, 0.25);
    border-radius: 0px 8px 8px 0px;

    display: flex;
    align-items: center;
    transition: width 1s ease-out;
}

.menu--small {
    position: absolute;
    width: 141px;
    height: 60px;
    left: 0px;
    top: 26px;
    transition: width 1s ease-out;
}

.logo {
    box-sizing: border-box;
    margin: 8px;
    margin-right: 20px;
    height: 90%;
    width: auto;
}

.menu--icon {
    width: 24px;
    height: auto;
    fill: #6EC2FF;
    opacity: 1;
    visibility: visible;
    transition: visibility 1s ease-out, width 1s ease-out, opacity 1s ease-out 0.5s;
}

.hidden {
    transition: visibility 1s ease-out, width 1s ease-out 1s, opacity 1s ease-out;
    visibility: hidden;
    width: 0px;
    opacity: 0;
}

svg:hover path {
    fill: #0D99FF;
}

.arrow {
    /* margin-left: 20px; */
    width: 10px;
    height: auto;
    transition: transform 0.5s linear;
    position: absolute;
    right: 8%;
    cursor: pointer;
}

.arrow--hidden {
    transform: rotate(180deg);
    /* right: -20%; */
    transition: transform 0.5s linear;
    margin-left: 0px;
    /* margin-right: 26px; */
}