.sidebar {
    background: radial-gradient(489.03% 95.56% at 71.37% 106.83%, rgba(13, 153, 255, 0.2) 0%, rgba(255, 255, 255, 0) 19.1%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(489.03% 95.56% at 71.37% 106.83%, rgba(13, 153, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #FFFFFF;
    border: 1px solid #0D99FF;
    box-shadow: 4px 4px 4px rgba(151, 182, 204, 0.25);

    position: absolute;
    width: 124px;
    height: 83vh; /* Make this Dynamic */
    left: 0px;
    top: 115px;

    border-radius: 0px 8px 8px 0px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: height ease-out 0.5s;
}

.hiddenSideBar {
    height: 50px;
    transition: height ease-out 0.5s;
}

.title {
    /* Pages */
    /* position: absolute;
    left: 32.26%;
    right: 36.29%;
    top: 1.78%;
    bottom: 96.67%; */
    margin: 15px 0px 20px 0px;

    font-family: 'Fredoka';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%   ;
    /* identical to box height, or 14px */
    /* display: flex;
    align-items: center; */
    color: #0F3D5F;
}

.classList {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
    height: 120px;
    width: 100%;
    margin-top: 20px;
}

.classItem {
    /* width: 41px; */
    height: 18px;

    font-family: 'Fredoka';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #97B6CC;

    cursor: pointer;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    opacity: 1;

    transition: opacity linear 0.5s, visibility linear 0.5s, transform linear 0.5s;
}

.hiddenClassItem {
    transform: translateY(-3vh);
    opacity: 0;
    visibility: hidden;
    transition: opacity linear 0.5s, visibility linear 0.5s, transform linear 0.5s;
}

.currentItem {
    color: #0D99FF;
}

.horizon-splitter{
    width: 44px;
    border: 1px solid rgba(183, 183, 183, 0.5);
    opacity: 1;
    transition: opacity linear 0.5s;
}

.hiddenSplitter {
    opacity: 0;
    transition: opacity linear 0.5s;
}

.sideArrow {
    height: 12px;
    color: #C1D3E0;
    transform: rotate(90deg);

    position: absolute;
    
    left: 82.32%;
    /* right: 30.23%;  */
    top: 15px;
    bottom: 96.33%;

    cursor: pointer;
}

.hiddenArrow {
    transform: rotate(270deg);
}