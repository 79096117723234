.grid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 12px; 

    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: -1;
    
    background: radial-gradient(81.64% 87.11% at 45.31% 104.8%, rgba(71, 112, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(72.79% 77.66% at 30.68% 15.59%, rgba(13, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(83.65% 94.86% at 17.64% 11.74%, rgba(71, 112, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(76.33% 86.56% at 29.09% 48.82%, rgba(13, 81, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(104.64% 118.66% at 97.79% 72.26%, rgba(13, 153, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #FFFFFF;
  }
  
  .row {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;

    width: 100%;
    height: 2px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .ellipse {
    width: 2px;
    height: 2px;
    
    background: rgba(151, 182, 204, 0.4);
    
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 5px;
  }
  
