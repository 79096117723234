.splitter {
    width: 0px;
    height: 32px;
    margin: 0px 12px 0px 12px;
    
    border: 1px solid #C1D3E0;
    /* transform: rotate(-90deg); */

    /* Inside auto layout */
    flex: none;
    /* order: 1; */
    /* flex-grow: 0; */
    
    opacity: 1;
    visibility: visible;
    transition: visibility 0.7s linear 0.5s, margin 0.7s linear 0s, opacity 0.7s linear 0.5s;
    transition-delay: 0s;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    margin: 0px;

    /* transition: visibility .5s ease, margin 0.5s ease, opacity .5s ease; */
    transition-delay: 0s;
}