.toolbar {
    position: absolute;
    height: 60px;
    width: 350px;

    right: 35%; 
    /* left: 35%; */
    /* right: 50%; */
    top: 26px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #0D99FF;
    box-shadow: 4px 4px 4px rgba(151, 182, 204, 0.25);
    border-radius: 8px;

    z-index: 1;
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: space-around;

    padding: 12px;
    transition: all 0.5s linear;
}

.vertical {
    position: absolute;
    transform: rotate(90deg) translate(0, -125px);
    right: 0px;   
    /* left: unset;  */
    top: 40%;
    transition: all 0.5s linear;
}

.switch--top {
    position: absolute;
    left: 95%;
    right: -40%;
    top: 10%;
    transition: linear 0.5s;
}

.switch--side {
    position: absolute;
    left: 1%;
    top: 5%;
    transform: rotate(-90deg);
    transition: linear 0.5s;
}

.switch--side:hover, .switch--top:hover {
    cursor: pointer;
}

@media (max-width: 1000px) {
    .toolbar {
        bottom: 10px;
        right: 20%;
        top: auto;
        transition: none;
    }
    .switch--side{
        transition: none;
    }
    .switch--top{
        transition: none;
    }
    .vertical {
        top: 40%;
        right: 0px;
        transform: rotate(90deg), translate(0, -125px);
        transition: none;
    }
}
@media (max-width: 600px) {
    .toolbar {
        right: 10%;
    }
    .vertical {
        right: 0px;
    }
}
/* @media (max-width: 600px) {

} */