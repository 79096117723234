.seat--container {
    /* This is the Text Portion */
    font-family: 'Fredoka';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */
    width: 126px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    color: #000000;

    position: absolute;
}

.seat--number {
    background: #FFFFFF;
    border: 2px solid #0D99FF;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.label {
    box-sizing: border-box;

    /* position: absolute;
    left: 0%;
    right: 0%;
    top: 57.14%;
    bottom: 0%; */
    
    height: 60px;
    width: 126px;
    left: 0px;
    top: 80px;
    border-radius: 10px;

    background: radial-gradient(84.92% 78.57% at 92.86% 21.43%, rgba(16, 198, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #FFFFFF;
    border: 1px solid #0D99FF;
    box-shadow: 4px 4px 4px rgba(151, 182, 204, 0.25);

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}